import { format, Locale, parse } from 'date-fns';
import { enGB, de, fr } from 'date-fns/locale';
import { DD_MM_YYYY, HH_MM } from '~/constants';
import { LocaleEnum } from '~/locales/resources';

/**
 * Parses a date range string and returns an array of Date objects.
 *
 * @param {string} dateRange - The date range string to parse, eg: 01.01.2023 — 05.01.2023
 * @return {Date[]} An array of Date objects representing the parsed dates.
 */
export const parseDateRangeStringToDates = (dateRange: string): Date[] => {
  if (!dateRange) return [];

  const dates: Date[] = dateRange
    .split(' — ')
    .map((date) => parse(date, DD_MM_YYYY, new Date()))
    .filter((date) => !isNaN(date.getTime()));

  if (dates.length === 0) return [];

  return dates.length === 2 ? dates : [dates[0], dates[0]];
};

/**
 * Parses an array of Date objects into a formatted date range string.
 *
 * @param {Date[]} dates - The array of Date objects to parse.
 * @return {string} The formatted date range string, or an empty string if the input is invalid.
 * @throws {Error} If an invalid date is provided.
 */
export const parseDatesToDateRangeString = (dates: Date[]): string => {
  if (!dates || dates.length === 0) return '';

  return dates
    .map((date) => {
      if (!(date instanceof Date) || isNaN(date.getTime())) {
        throw new Error('Invalid date provided');
      }
      return formatDate(date, LocaleEnum.DE);
    })
    .join(' — ');
};

/**
 * Check if today's date has passed the given payment due date.
 * @param paymentDueDate - The payment due date in the format "YYYY-MM-DD".
 * @returns true if today has passed the payment due date, otherwise false.
 */
export const hasPassedPaymentDueDate = (
  paymentDueDate: string | Date
): boolean => {
  const today = new Date();
  const dueDate = new Date(paymentDueDate);

  // Set the time of today and dueDate to the start of the day for accurate comparison
  today.setHours(0, 0, 0, 0);
  dueDate.setHours(0, 0, 0, 0);

  return today > dueDate;
};

export const formatDate = (
  date: Date,
  locale?: LocaleEnum,
  extened?: boolean
): string => {
  if (!date) return '';
  const localeMap = {
    [LocaleEnum.EN]: enGB,
    [LocaleEnum.DE]: de,
    [LocaleEnum.FR]: fr,
  } as { [key in LocaleEnum]: Locale };
  return format(date, extened ? `${DD_MM_YYYY}, ${HH_MM}` : DD_MM_YYYY, {
    locale: localeMap[locale ?? LocaleEnum.DE],
  });
};
