export const DD_MM_YYYY = 'dd.MM.yyyy';
export const HH_MM = 'HH:mm';
export const DB_DATE_FORMAT = 'yyyy-MM-dd';

export const API_HOST: string = `http://${process.env.BUYER_APP_SERVICE_URL}`;
export const MOCK_HOST = process.env.MOCK_HOST;

export enum FontWeight {
  Thin = 400,
  Normal = 500,
  Bold = 600,
  ExtraBold = 800,
}

export const EMAIL_LOGISTICS = 'logistics@andercore.com';
export const CONTACT_NUMBER = '+49 30 30809217';

export const DEFAULT_PAGE_SIZE = 20;
export const VALUES_SEPARATOR = '~';

export const BULLET_POINT = ' • ';

export const USER_SETTINGS_EMPTY = {
  company_information: {
    name: '',
    address: {
      street: '',
      postal_code: '',
      city: null,
      country: null,
    },
  },
  user_data: {
    first_name: '',
    last_name: '',
    email: '',
  },
  account_manager: {
    name: '',
    telephone: null,
    email: '',
  },
  preferences: {
    locale: null,
    notification: {
      delivery_updates: {
        email: {
          enabled: false,
          recipient: '',
        },
        sms: {
          enabled: false,
          recipient: null,
        },
        whatsapp: {
          enabled: false,
          recipient: null,
        },
      },
      newsletter: {
        email: {
          enabled: false,
          recipient: null,
        },
      },
      storeLaunch: false,
    },
  },
};

export const SEARCH_PARAM_SET_PASSWORD_MODAL = 'set-password-modal';
export const SEARCH_PARAM_RESOLVE_CLAIM_MODAL = 'resolve-claim-modal';
