/** @type {import('tailwindcss').Config} */
module.exports = {
  content: ['./src/**/*.{js,ts,jsx,tsx,mdx}'],
  theme: {
    extend: {
      fontSize: {
        '2xs': '0.6rem',
      },
      flex: {
        2: '2 2 0%',
        3: '3 3 0%',
        4: '4 4 0%',
        5: '5 5 0%',
      },
      colors: {
        primary: '#3B3F44',
        secondary: '#D30B0B',
        red: {
          50: '#FFF5F5',
          100: '#FED7D7',
          500: '#E53E3E',
          600: '#D30B0B',
          700: '#9B2C2C',
          800: '#822727',
        },
        gray: {
          10: '#F3F3F3',
          50: '#F8F9FA',
          100: '#EBEDEF',
          400: '#BDC4CB',
          700: '#646D74',
          800: '#55616D',
          900: '#3D3D3D',
        },
        pink: {
          50: '#FAE0E2',
          400: '#C03744',
          800: '#8C1823',
          900: '#3B3F44',
        },
        green: {
          50: '#F0FFF4',
          100: '#C6F6D5',
          200: '#F0FFF4',
          300: '#CFF1DF',
          400: '#48BB78',
          500: '#38A169',
          600: '#4FB36A',
          800: '#22543D',
          900: '#3B3F44',
        },
        green: {
          100: '#C6F6D5',
          200: '#F0FFF4',
          300: '#CFF1DF',
          500: '#38A169',
          600: '#4FB36A',
          900: '#22543D',
        },
      },
    },
    transitionDuration: {
      75: '75ms',
      200: '200ms',
      300: '300ms',
      400: '400ms',
      500: '500ms',
      600: '600ms',
      700: '700ms',
      750: '750ms',
      1000: '1000ms',
      1500: '1500ms',
      2000: '2000ms',
      2500: '2500ms',
      3000: '3000ms',
      3500: '3500ms',
      4000: '4000ms',
      4500: '4500ms',
      5000: '5000ms',
    },
    spacing: {
      0: 0,
      px: 1,
      0.5: 2,
      1: 4,
      1.5: 6,
      2: 8,
      2.5: 10,
      3: 12,
      3.5: 14,
      4: 16,
      5: 20,
      6: 24,
      7: 28,
      8: 32,
      9: 36,
      10: 40,
      11: 44,
      12: 48,
      14: 56,
      16: 64,
      20: 80,
      22: 88,
      24: 96,
      28: 112,
      30: 120,
      32: 128,
      36: 144,
      40: 160,
      44: 176,
      46: 184,
      48: 192,
      52: 208,
      54: 216,
      56: 224,
      60: 240,
      64: 256,
      72: 288,
      80: 320,
      96: 384,
      192: 768,
    },
    zIndex: {
      60: '60',
      70: '70',
      80: '80',
      90: '90',
      100: '100',
    },
  },
  plugins: [],
};
