'use client';
import { Flex, FlexProps } from '@chakra-ui/react';

import AndercoreA from '~/icons/andercore-a.svg';
import AndercoreMobile from '~/icons/andercore-logo-small.svg';
import AndercoreBig from '~/icons/andercore-logo-big.svg';
import Battery from '~/icons/battery.svg';
import Billing from '~/icons/billing.svg';
import Cart from '~/icons/cart.svg';
import DarkFinancing from '~/icons/dark/financing.svg';
import DarkLogistics from '~/icons/dark/logistics.svg';
import DarkStorage from '~/icons/dark/storage.svg';
import Dashboard from '~/icons/dashboard.svg';
import Delivery from '~/icons/delivery.svg';
import Download from '~/icons/download.svg';
import Pencil from '~/icons/edit-pencil.svg';
import FileAttachement from '~/icons/file-attachement.svg';
import Logout from '~/icons/logout.svg';
import Notification from '~/icons/notification.svg';
import OfficeBuilding from '~/icons/office-building.svg';
import Orders from '~/icons/orders.svg';
import Power from '~/icons/power.svg';
import Printer from '~/icons/printer.svg';
import Profile from '~/icons/profile.svg';
import Quotes from '~/icons/quotes.svg';
import Scale from '~/icons/scale.svg';
import Settings from '~/icons/settings.svg';
import Space from '~/icons/space.svg';
import Shop from '~/icons/shop.svg';
import Home from '~/icons/home.svg';
import Minus from '~/icons/minus.svg';
import Expand from '~/icons/expand.svg';
import Collapse from '~/icons/collapse.svg';
import ChatSad from '~/icons/chat-sad.svg';
import SendEmail from '~/icons/send-email.svg';
import Mail from '~/icons/mail.svg';
import ChevronDown from '~/icons/chevron-down.svg';
import CheckCircle from '~/icons/check-circle.svg';
import ChevronUp from '~/icons/chevron-up.svg';
import ArrowLeft from '~/icons/arrow-left.svg';
import Timer from '~/icons/timer.svg';
import Location from '~/icons/location.svg';
import HomeBig from '~/icons/home-big.svg';
import ZoomOutMap from '~/icons/zoom-out-map.svg';
import Hamburger from '~/icons/hamburger.svg';
import Store from '~/icons/store.svg';
import DeliveryTruck from '~/icons/delivery-truck.svg';
import ChatBubble from '~/icons/chat-bubble-oval-smiley.svg';
import CoffeeMug from '~/icons/coffee-mug.svg';
import CategoryInverter from '~/icons/category-inverter.svg';
import InvoiceDue from '~/icons/invoice-due.svg';
import CoinMany from '~/icons/coin-many.svg';
import BusinessHandshake from '~/icons/business-handshake.svg';
import Sustainability from '~/icons/sustainability.svg';
import ArrowsHorizontal from '~/icons/track-and-trace/arrows-horizontal.svg';
import CustomerSupport from '~/icons/track-and-trace/customer-support.svg';
import Envelope from '~/icons/envelope.svg';
import InterfaceCalendar from '~/icons/track-and-trace/interface-calendar.svg';
import LocationOffice from '~/icons/track-and-trace/location-office.svg';
import Phone from '~/icons/track-and-trace/phone.svg';
import Delivered from '~/icons/track-and-trace/progress-bar/delivered.svg';
import InManufacturing from '~/icons/track-and-trace/progress-bar/in-manufacturing.svg';
import OrderPlaced from '~/icons/track-and-trace/progress-bar/order-placed.svg';
import SecurityOfficer from '~/icons/track-and-trace/progress-bar/security-officer.svg';
import Ship from '~/icons/track-and-trace/progress-bar/ship.svg';
import TransferCart from '~/icons/track-and-trace/progress-bar/transfer-cart.svg';
import Truck from '~/icons/track-and-trace/progress-bar/truck.svg';
import ChevronRight from '~/icons/track-and-trace/progress-bar/chevron-right.svg';
import ShippingBox from '~/icons/track-and-trace/shipping-box.svg';
import TravelMapLocation from '~/icons/track-and-trace/travel-map-location.svg';
import TrackAndTrace from '~/icons/track-and-trace.svg';
import TravelMapLocationPin from '~/icons/travel-map-location-pin.svg';
import Upload from '~/icons/upload.svg';
import Upward from '~/icons/upward.svg';
import Wallet from '~/icons/wallet.svg';
import DisputeBubble from '~/icons/dispute-bubble.svg';
import EmptyBilling from '~/icons/empty-state/billing.svg';
import EmptyOrders from '~/icons/empty-state/orders.svg';
import EmptyQuotes from '~/icons/empty-state/quotes.svg';
import EmptyNotFound from '~/icons/empty-state/notfound.svg';
import EmptyTrackAndTrace from '~/icons/empty-state/track-n-trace.svg';
import EmptyTrackAndTraceSearch from '~/icons/empty-state/track-n-trace-search.svg';
import Coins from '~/icons/coins.svg';
import Language from '~/icons/language.svg';
import Smartphone from '~/icons/smartphone.svg';
// import ShippingBox from '~/icons/shipping-box.svg';
import WhatsApp from '~/icons/whats-app.svg';
import QuoteRequestAccept from '~/icons/quote-request/accept.svg';
import QuoteRequestModify from '~/icons/quote-request/modify.svg';
import QuoteRequestReject from '~/icons/quote-request/reject.svg';
import QuoteRequestExpired from '~/icons/quote-request/expired.svg';
// Store
import Delivery2 from '~/icons/store/delivery.svg';
import Storage from '~/icons/store/storage.svg';
import Search from '~/icons/store/search.svg';
import Modules from '~/icons/store/modules.svg';
import Inverters from '~/icons/store/inverters.svg';
import Batteries from '~/icons/store/batteries.svg';
import Cables from '~/icons/store/cables.svg';
import Add from '~/icons/add.svg';
import Cancel from '~/icons/cancel.svg';
import Percent from '~/icons/percent.svg';
import Warranty from '~/icons/store/warranty.svg';
import OutputPower from '~/icons/store/outputPower.svg';
import ModulePower from '~/icons/store/modulePower.svg';
import CellType from '~/icons/store/cellType.svg';
import ModuleType from '~/icons/store/moduleType.svg';
import AcPowerRange from '~/icons/store/acPowerRange.svg';
import MaxDCVoltage from '~/icons/store/maxDCVoltage.svg';
import InverterPower from '~/icons/store/inverterPower.svg';
import InverterType from '~/icons/store/inverterType.svg';
import NumberOfPhases from '~/icons/store/numberOfPhases.svg';
import Capacity from '~/icons/store/capacity.svg';
import BatteryVoltage from '~/icons/store/batteryVoltage.svg';
import CableType from '~/icons/store/cableType.svg';
import Colour from '~/icons/store/colour.svg';
import Length from '~/icons/store/length.svg';
import SupportContact from '~/icons/supportContact.svg';
import Pallet from '~/icons/store/pallet.svg';
import Container from '~/icons/store/container.svg';
import PanelEfficiency from '~/icons/store/panelEfficiency.svg';
import NoResults from '~/icons/store/filter-no-results.svg';
import Filter from '~/icons/store/filter.svg';
// Claim management
import ClaimManagement from '~/icons/claimManagement/claimManagement.svg';
import Quality from '~/icons/claimManagement/quality.svg';
import DeliveryDamage from '~/icons/claimManagement/delivery.svg';
import ClaimBilling from '~/icons/claimManagement/billing.svg';
import Calendar from '~/icons/claimManagement/calendar.svg';
import LastUpdated from '~/icons/claimManagement/lastUpdated.svg';
import OtherReason from '~/icons/claimManagement/reasons/other.svg';
import LateDelivery from '~/icons/claimManagement/reasons/late-delivery.svg';
import WrongQuantity from '~/icons/claimManagement/reasons/wrong-quantity.svg';
import PackagingDamage from '~/icons/claimManagement/reasons/packaging-damage.svg';
import Performance from '~/icons/claimManagement/reasons/performance.svg';
import ReasonWarranty from '~/icons/claimManagement/reasons/warranty.svg';
import SerialNumber from '~/icons/claimManagement/reasons/serial-number.svg';
import ReasonSoftware from '~/icons/claimManagement/reasons/software.svg';
import ProductionDate from '~/icons/claimManagement/reasons/production-date.svg';
import ProductQuantity from '~/icons/claimManagement/reasons/product-quantity.svg';
import BillingInformation from '~/icons/claimManagement/reasons/billing-information.svg';
import WrongProducts from '~/icons/claimManagement/reasons/wrong-products.svg';
import PaymentAmount from '~/icons/claimManagement/reasons/payment-amount.svg'; // eslint-disable-line import/order
import PaidAlready from '~/icons/claimManagement/reasons/paid-already.svg';
import NoComments from '~/icons/claimManagement/noComments.svg';
import NoClaims from '~/icons/empty-state/claim.svg';
import MessageRead from '~/icons/claimManagement/messageRead.svg';
import MaterialDefect from '~/icons/claimManagement/reasons/material-defect.svg';
import Resolved from '~/icons/claimManagement/resolved.svg';
import Document from '~/icons/document.svg';
import Clock from '~/icons/clock.svg';
import Loader from '~/icons/loader.svg';
import Paperplane from '~/icons/paperplane.svg';
import Check from '~/icons/check.svg';
import Circle from '~/icons/circle.svg';
import EyeOpen from '~/icons/eye-open.svg';
import EyeClosed from '~/icons/eye-closed.svg';
import Alert from '~/icons/alert.svg';
import Globe from '~/icons/globe.svg';
import DE from '~/icons/country/de.svg';
import FR from '~/icons/country/fr.svg';
import EN from '~/icons/country/en.svg';
import IT from '~/icons/country/it.svg';
import ES from '~/icons/country/es.svg';
import { Suspense } from 'react';
import { colors } from '~/utils/tailwindUtils';
import { ColorModeEnum } from '~/utils/generalUtils';

interface IconType {
  [key: string]: React.SVGProps<SVGSVGElement>;
}

export enum IconsName {
  Home = 'home',
  AndercoreA = 'AndercoreA',
  AndercoreMobile = 'AndercoreMobile',
  AndercoreBig = 'AndercoreBig',
  TrackAndTrace = 'track-and-trace',
  SecurityOfficer = 'security-officer',
  CustomerSupport = 'customer-support',
  InterfaceCalendar = 'interface-calendar',
  LocationOffice = 'location-office',
  ShippingBox = 'shipping-box',
  ArrowsHorizontal = 'arrows-horizontal',
  Envelope = 'envelope',
  Phone = 'phone',
  TravelMapLocation = 'travel-map-location',
  OrderPlaced = 'order-placed',
  Delivered = 'delivered',
  InManufacturing = 'in-manufacturing',
  Ship = 'ship',
  Truck = 'truck',
  TransferCart = 'transfer-cart',
  Wallet = 'wallet',
  Power = 'power',
  Battery = 'battery',
  Scale = 'scale',
  Space = 'space',
  Upward = 'upward',
  DarkStorage = 'dark-storage',
  DarkLogistics = 'dark-logistics',
  DarkFinancing = 'dark-financing',
  OfficeBuilding = 'office-building',
  TravelMapLocationPin = 'travel-map-location-pin',
  Billing = 'billing',
  Pencil = 'pencil',
  FileAttachement = 'fileAttachement',
  Settings = 'settings',
  Logout = 'logout',
  Dispute = 'dispute',
  EmptyBilling = 'empty-billing',
  EmptyNotFound = 'empty-not-found',
  EmptyOrders = 'empty-orders',
  EmptyQuotes = 'empty-quotes',
  EmptyTrackAndTrace = 'empty-track-and-trace',
  EmptyTrackAndTraceSearch = 'empty-track-and-trace-search',
  Coins = 'coins',
  Language = 'language',
  Smartphone = 'smartphone',
  WhatsApp = 'whatsapp',
  QuoteRequestAccept = 'quote-request-accept',
  QuoteRequestModify = 'quote-request-modify',
  QuoteRequestReject = 'quote-request-reject',
  QuoteRequestExpired = 'quote-request-expired',
  Delivery2 = 'delivery2',
  Storage = 'storage',
  Search = 'search',
  Cables = 'cables',
  Batteries = 'batteries',
  Inverters = 'inverters',
  Modules = 'modules',
  Add = 'add',
  Cancel = 'cancel',
  Percent = 'percent',
  Warranty = 'warranty',
  OutputPower = 'outputPower',
  ModulePower = 'modulePower',
  PanelEfficiency = 'panelEfficiency',
  CellType = 'cellType',
  ModuleType = 'moduleType',
  InverterType = 'inverterType',
  AcPowerRange = 'acPowerRange',
  MaxDCVoltage = 'maxDCVoltage',
  InverterPower = 'inverterPower',
  NumberOfPhases = 'numberOfPhases',
  Capacity = 'capacity',
  BatteryVoltage = 'batteryVoltage',
  CableType = 'cableType',
  Colour = 'colour',
  Length = 'length',
  SupportContact = 'supportContact',
  Pallet = 'pallet',
  Container = 'container',
  NoResults = 'noResults',
  Filter = 'filter',
  ClaimManagement = 'claimManagement',
  Quality = 'quality',
  DeliveryDamage = 'claimDelivery',
  ClaimBilling = 'claimBilling',
  Calendar = 'calendar',
  LastUpdated = 'last-updated',
  OtherReason = 'otherReason',
  LateDelivery = 'lateDelivery',
  WrongQuantity = 'wrongQuantity',
  PackagingDamage = 'packagingDamage',
  Performance = 'performance',
  SerialNumber = 'serialNumber',
  ReasonWarranty = 'reasonWarranty',
  ReasonSoftware = 'reasonSoftware',
  ProductionDate = 'productionDate',
  ProductQuantity = 'productQuantity',
  BillingInformation = 'billingInformation',
  PaymentAmount = 'paymentAmount',
  WrongProducts = 'wrongProducts',
  PaidAlready = 'paidAlready',
  NoComments = 'noComments',
  NoClaims = 'noClaims',
  MessageRead = 'messageRead',
  MaterialDefect = 'materialDefect',
  Resolved = 'resolved',
  Document = 'document',
  Clock = 'clock',
  Loader = 'Loader',
  Paperplane = 'paperplane',
  Check = 'check',
  Circle = 'circle',
  EyeOpen = 'eyeOpen',
  EyeClosed = 'eyeClosed',
  Alert = 'alert',
  Shop = 'shop',
  Globe = 'globe',
  Quotes = 'quotes',
  Orders = 'orders',
  DE = 'DE',
  FR = 'FR',
  EN = 'EN',
  IT = 'IT',
  ES = 'ES',
}

const iconTypes: IconType = {
  [IconsName.AndercoreA]: AndercoreA,
  [IconsName.AndercoreMobile]: AndercoreMobile,
  [IconsName.AndercoreBig]: AndercoreBig,
  dashboard: Dashboard,
  [IconsName.Home]: Home,
  minus: Minus,
  expand: Expand,
  collapse: Collapse,
  sendEmail: SendEmail,
  chatSad: ChatSad,
  mail: Mail,
  chevronUp: ChevronUp,
  chevronDown: ChevronDown,
  checkCircle: CheckCircle,
  homeBig: HomeBig,
  arrowLeft: ArrowLeft,
  timer: Timer,
  location: Location,
  zoomOutMap: ZoomOutMap,
  hamburger: Hamburger,
  store: Store,
  deliveryTruck: DeliveryTruck,
  chatBubble: ChatBubble,
  coffeeMug: CoffeeMug,
  categoryInverter: CategoryInverter,
  businessHandshake: BusinessHandshake,
  coinMany: CoinMany,
  invoiceDue: InvoiceDue,
  chevronRight: ChevronRight,
  [IconsName.Shop]: Shop,
  [IconsName.Quotes]: Quotes,
  [IconsName.Orders]: Orders,
  delivery: Delivery,
  [IconsName.TrackAndTrace]: TrackAndTrace,
  cart: Cart,
  notification: Notification,
  profile: Profile,
  download: Download,
  upload: Upload,
  printer: Printer,
  [IconsName.Power]: Power,
  [IconsName.Battery]: Battery,
  [IconsName.Scale]: Scale,
  [IconsName.Space]: Space,
  [IconsName.Upward]: Upward,
  [IconsName.DarkFinancing]: DarkFinancing,
  [IconsName.DarkLogistics]: DarkLogistics,
  [IconsName.DarkStorage]: DarkStorage,
  [IconsName.Ship]: Ship,
  sustainability: Sustainability,
  [IconsName.Wallet]: Wallet,
  [IconsName.OrderPlaced]: OrderPlaced,
  [IconsName.Delivered]: Delivered,
  [IconsName.InManufacturing]: InManufacturing,
  [IconsName.SecurityOfficer]: SecurityOfficer,
  [IconsName.CustomerSupport]: CustomerSupport,
  [IconsName.InterfaceCalendar]: InterfaceCalendar,
  [IconsName.LocationOffice]: LocationOffice,
  [IconsName.ShippingBox]: ShippingBox,
  [IconsName.ArrowsHorizontal]: ArrowsHorizontal,
  [IconsName.Envelope]: Envelope,
  [IconsName.Phone]: Phone,
  [IconsName.TravelMapLocation]: TravelMapLocation,
  [IconsName.Truck]: Truck,
  [IconsName.TransferCart]: TransferCart,
  [IconsName.OfficeBuilding]: OfficeBuilding,
  [IconsName.TravelMapLocationPin]: TravelMapLocationPin,
  [IconsName.Billing]: Billing,
  [IconsName.Pencil]: Pencil,
  [IconsName.FileAttachement]: FileAttachement,
  [IconsName.Settings]: Settings,
  [IconsName.Logout]: Logout,
  [IconsName.Dispute]: DisputeBubble,
  [IconsName.EmptyBilling]: EmptyBilling,
  [IconsName.EmptyNotFound]: EmptyNotFound,
  [IconsName.EmptyOrders]: EmptyOrders,
  [IconsName.EmptyQuotes]: EmptyQuotes,
  [IconsName.EmptyTrackAndTrace]: EmptyTrackAndTrace,
  [IconsName.EmptyTrackAndTraceSearch]: EmptyTrackAndTraceSearch,
  [IconsName.Coins]: Coins,
  [IconsName.Language]: Language,
  [IconsName.Smartphone]: Smartphone,
  [IconsName.WhatsApp]: WhatsApp,
  [IconsName.QuoteRequestAccept]: QuoteRequestAccept,
  [IconsName.QuoteRequestModify]: QuoteRequestModify,
  [IconsName.QuoteRequestReject]: QuoteRequestReject,
  [IconsName.QuoteRequestExpired]: QuoteRequestExpired,
  [IconsName.Delivery2]: Delivery2,
  [IconsName.Storage]: Storage,
  [IconsName.Search]: Search,
  [IconsName.Modules]: Modules,
  [IconsName.Batteries]: Batteries,
  [IconsName.Cables]: Cables,
  [IconsName.Inverters]: Inverters,
  [IconsName.Add]: Add,
  [IconsName.Cancel]: Cancel,
  [IconsName.Percent]: Percent,
  [IconsName.Warranty]: Warranty,
  [IconsName.OutputPower]: OutputPower,
  [IconsName.ModulePower]: ModulePower,
  [IconsName.PanelEfficiency]: PanelEfficiency,
  [IconsName.CellType]: CellType,
  [IconsName.ModuleType]: ModuleType,
  [IconsName.InverterType]: InverterType,
  [IconsName.AcPowerRange]: AcPowerRange,
  [IconsName.MaxDCVoltage]: MaxDCVoltage,
  [IconsName.InverterPower]: InverterPower,
  [IconsName.NumberOfPhases]: NumberOfPhases,
  [IconsName.Capacity]: Capacity,
  [IconsName.BatteryVoltage]: BatteryVoltage,
  [IconsName.CableType]: CableType,
  [IconsName.Colour]: Colour,
  [IconsName.Length]: Length,
  [IconsName.SupportContact]: SupportContact,
  [IconsName.Pallet]: Pallet,
  [IconsName.Container]: Container,
  [IconsName.NoResults]: NoResults,
  [IconsName.Filter]: Filter,
  [IconsName.ClaimManagement]: ClaimManagement,
  [IconsName.Quality]: Quality,
  [IconsName.DeliveryDamage]: DeliveryDamage,
  [IconsName.ClaimBilling]: ClaimBilling,
  [IconsName.Calendar]: Calendar,
  [IconsName.LastUpdated]: LastUpdated,
  [IconsName.LateDelivery]: LateDelivery,
  [IconsName.WrongQuantity]: WrongQuantity,
  [IconsName.PackagingDamage]: PackagingDamage,
  [IconsName.OtherReason]: OtherReason,
  [IconsName.Performance]: Performance,
  [IconsName.SerialNumber]: SerialNumber,
  [IconsName.ReasonWarranty]: ReasonWarranty,
  [IconsName.ReasonSoftware]: ReasonSoftware,
  [IconsName.ProductionDate]: ProductionDate,
  [IconsName.ProductQuantity]: ProductQuantity,
  [IconsName.BillingInformation]: BillingInformation,
  [IconsName.PaymentAmount]: PaymentAmount,
  [IconsName.PaidAlready]: PaidAlready,
  [IconsName.NoComments]: NoComments,
  [IconsName.NoClaims]: NoClaims,
  [IconsName.MessageRead]: MessageRead,
  [IconsName.MaterialDefect]: MaterialDefect,
  [IconsName.WrongProducts]: WrongProducts,
  [IconsName.Resolved]: Resolved,
  [IconsName.Document]: Document,
  [IconsName.Clock]: Clock,
  [IconsName.Loader]: Loader,
  [IconsName.Paperplane]: Paperplane,
  [IconsName.Check]: Check,
  [IconsName.Circle]: Circle,
  [IconsName.EyeOpen]: EyeOpen,
  [IconsName.EyeClosed]: EyeClosed,
  [IconsName.Alert]: Alert,
  [IconsName.Globe]: Globe,
  [IconsName.DE]: DE,
  [IconsName.FR]: FR,
  [IconsName.EN]: EN,
  [IconsName.IT]: IT,
  [IconsName.ES]: ES,
};

export interface IconProps extends FlexProps {
  name: string;
  size?: number;
  width?: number;
  height?: number;
  hovered?: boolean;
  color?: string;
  colorMode?: 'fill' | 'stroke';
  customStrokeWidth?: number;
}

export const Icon = ({
  name,
  size = 24,
  width,
  height,
  hovered,
  color = '#3B3F44',
  colorMode = ColorModeEnum.Stroke,
  customStrokeWidth, // FIXME: Remove this prop once the component is refactored
  ...restProps
}: IconProps) => {
  let Icon = (iconTypes[name] || Home) as React.FC<
    React.SVGProps<SVGSVGElement>
  >;

  const strokeWidth = colorMode === ColorModeEnum.Stroke ? 1 : 0;
  const fill = colorMode === ColorModeEnum.Fill ? color : 'none';

  return (
    <Flex align={'center'} justify={'center'} {...restProps}>
      <Suspense fallback={<></>}>
        <Icon
          data-testid="icon"
          stroke={hovered ? colors.red[600] : color}
          height={height ?? size}
          width={width ?? size}
          strokeWidth={customStrokeWidth || strokeWidth}
          fill={fill}
        />
      </Suspense>
    </Flex>
  );
};
