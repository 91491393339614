import twcolors from 'tailwindcss/colors';
import tailwindConfig from '../../tailwind.config';
import { deepMerge } from './generalUtils';

/**
 * A utility that exports a mapping of Tailwind CSS spacing values to their corresponding pixel values.
 *
 * The `spacing` object provides a record where the keys are the Tailwind CSS spacing scale values
 * and the values are the actual pixel values (numbers only). This can be useful for dynamically
 * setting spacing in styles without directly using CSS classes.
 *
 * Note: These values are intended for use in JavaScript/TypeScript and should not be used as CSS classes.
 *
 * @example
 * // Accessing the spacing value for '24' (equivalent to 6rem or 96px in Tailwind CSS)
 * const spacingValue = spacing['24']; // 96
 *
 * @see {@link https://tailwindcss.com/docs/customizing-spacing#default-spacing-scale}
 */
export const spacing: Record<string, number> = tailwindConfig.theme!
  .spacing as unknown as Record<string, number>;

/**
 * A utility that exports the Tailwind CSS colors object.
 *
 * The `colors` object provides access to all the color values defined by Tailwind CSS.
 * This can be useful for dynamically setting colors in styles without directly using CSS classes.
 *
 * Note: These values are intended for use in JavaScript/TypeScript and should not be used as CSS classes.
 *
 * @example
 * // Accessing a color value
 * const primaryColor = colors.blue['500']; // #3B82F6
 *
 */

// Extract custom colors from tailwindConfig
const customColors = tailwindConfig.theme!.extend!.colors as unknown as Record<
  string,
  number
>;

// Deep merge Tailwind colors with custom colors
export const colors = deepMerge(twcolors, customColors);
