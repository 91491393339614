// NOTE: When a new language is added here, fetch-translations script should be modified as well

/**
 * Supported locales/languages in the application
 */
export enum LocaleEnum {
  EN = 'en',
  DE = 'de',
  FR = 'fr',
  IT = 'it',
  ES = 'es',
}

/**
 * Translation files for supported languages
 */
export const translationFiles = {
  en: () => import('./.translations/en'),
  de: () => import('./.translations/de'),
  fr: () => import('./.translations/fr'),
  it: () => import('./.translations/it'),
  es: () => import('./.translations/es'),
};
